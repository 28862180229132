import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import classNames from "classnames";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations";
import {Navbar} from "./components/Navbar";
import HomePage from "./pages/Home";

i18n
    .use(initReactI18next)
    .init({
      resources: translations,
      lng: "en",
      fallbackLng: "en",

      interpolation: {
        escapeValue: false
      }
    });

const withNavBar = (Component) => () => (
    <React.Fragment>
      <Navbar/>
      <Component/>
    </React.Fragment>
);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
      <React.Fragment>
        <div className={classNames('App')}>
          <Router>
            <ScrollToTop/>
            <Routes>
                <Route path="/*" element={withNavBar(HomePage)()}/>
            </Routes>
          </Router>
        </div>
      </React.Fragment>
  );
}

export default App;
