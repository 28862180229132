const classNames = require('classnames');

export const Section = ({id, className, children, dark}) => {
    return (
        <div id={id}
             className={classNames('Section', className, {
                 'Section--Dark': dark
             })}>
            {children}
        </div>
    )
}

export const SectionH1 = ({text}) => {
    return (
        <h1>
            {text}
        </h1>
    )
}

export const SectionH2 = ({text}) => {
    return (
        <h2>
            {text}
        </h2>
    )
}

export const SectionP = ({text}) => {
    return (
        <p>
            {text}
        </p>
    )
}

export default Section;
