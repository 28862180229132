import _ from 'lodash';
import React, {useEffect, useState} from "react";
import {NavHashLink, HashLink} from 'react-router-hash-link';
import { useNavigate } from "react-router-dom";
import { useTranslation, initReactI18next } from "react-i18next";
import {push as Menu} from 'react-burger-menu';
import classNames from "classnames";
import SocialIcons from "./SocialIcons";
import useMediaQuery from "../hooks/useMediaQuery";

import logo from '../assets/logos/zero-degree@2x.png';
import hamburgerIcon from '../assets/icons/navbar/menu@2x.png';
import hamburgerXIcon from '../assets/icons/navbar/menu-x@2x.png';

const NavLink = NavHashLink;

export const HeaderLink = ({to, target, children, className, onClick, as}) => {
    if(target){
        return (
            <a href={to}
               target={target}
               className={classNames("NavbarLink", className)}
               onClick={onClick}
            >
                {children}
            </a>
        );
    }
    if(_.isNil(to)){
        return (
            <div
                className={"NavbarLink"}
                onClick={onClick}
            >
                {children}
            </div>
        )
    }
    return (
        <NavLink to={to}
                 target={target}
                 className={({ isActive }) => classNames("NavbarLink", {
                     'NavbarLink--active': isActive,
                     [className]: true
                 })}
                 onClick={onClick}
        >
            {children}
        </NavLink>
    );
}

const SubMenuLink = ({to, children}) => {
    return (
        <li className={'NavbarLink__submenu-item'}
            onClick={(e)=> {
                e.preventDefault();
                e.stopPropagation()
            }}
        >
            <HashLink to={to}>
                {children}
            </HashLink>
        </li>
    );
}

export const BottomNavigator = ({prev, next}) => {
    return (
        <div className={'BottomNavigator'}>
            {
                prev &&
                <NavLink to={prev.to}
                         // className='NavbarLink'
                >
                    {`< ${prev.title}`}
                </NavLink>
            }
            {
                next &&
                <NavLink to={next.to}
                         // className='NavbarLink'
                >
                    {`${next.title} >`}
                </NavLink>
            }
        </div>
    )
}


export const Navbar = ({}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(768);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const onOpenClick = () => {
        setIsMenuOpen(true);
    };
    const onCloseClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const onMenuStateChange = (state) => {
        setIsMenuOpen(state.isOpen);
    };
    const links = (
        <>
            <HeaderLink to={'/#about'}
                        onClick={onCloseClick}
            >
                About
            </HeaderLink>
            <HeaderLink to={'/#team'}
                        onClick={onCloseClick}
            >
                Team
            </HeaderLink>
            <HeaderLink to={'/#platform-overview'}
                        onClick={onCloseClick}
            >
                Platform Overview
            </HeaderLink>
            <HeaderLink to={'/#industries'}
                        onClick={onCloseClick}
            >
                Industries
            </HeaderLink>
        </>
    );

    return (
        <div className={'Navbar'}>
            {
                isMobile &&
                <React.Fragment>
                    <a onClick={onOpenClick}
                       className={'NavbarHamburgerButton'}
                    >
                        <img src={hamburgerIcon}/>
                    </a>
                    <div className={'Navbar__content'}>
                        <NavLink to={'/'}
                                 className={'Navbar__logo-wrapper'}>
                            <img src={logo}
                                 className={'Navbar__logo mobile-only'}
                            />
                        </NavLink>
                    </div>
                    <Menu isOpen={isMenuOpen}
                          disableAutoFocus
                          itemListElement='div'
                          customBurgerIcon={false}
                          customCrossIcon={false}
                          onStateChange={onMenuStateChange}>
                        <div className={'NavBarMenuHeader'}>
                            <a onClick={onCloseClick}
                               className={'NavBarMenuHeader__close'}>
                                <img src={hamburgerXIcon}/>
                            </a>
                            {links}
                        </div>
                        {/*<SocialIcons/>*/}
                    </Menu>
                </React.Fragment>
            }
            {
                !isMobile &&
                <div className={'Navbar__content'}>
                    <div className={'Navbar__links'}>
                        <NavLink to={'/'}>
                            <img src={logo}
                                 className={'Navbar__logo'}
                            />
                        </NavLink>
                        {links}
                    </div>
                    {/*<SocialIcons/>*/}
                </div>
            }

        </div>
    )
}
