import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import Section from "../components/Section";
import classNames from "classnames";

const AdvisorMember = ({avatar, name, title, logo, externalLink, className}) => {
    return (
        <a className={classNames('AdvisorMember', className)}
           href={externalLink}
           target={'_blank'}
        >
            <img className={'AdvisorMember__avatar'}
                 src={`/images/avatars/${avatar}`}/>

            <div className={'AdvisorMember__name'}>
                {name}
            </div>
            <div className={'AdvisorMember__title'}>
                {title}
            </div>
            {
                logo &&
                <div className={'AdvisorMember__logo-wrapper'}>
                    <img className={'TeamMember__logo'} src={logo}/>
                </div>
            }
        </a>
    )
}

const TeamMember = ({avatar, name, title, logo, externalLink, className}) => {
    return (
        <a className={classNames('TeamMember', className)}
           href={externalLink}
           target={'_blank'}
        >
            <img className={'TeamMember__avatar'}
                 src={`/images/avatars/${avatar}`}/>

            <div className={'TeamMember__name'}>
                {name}
            </div>
            <div className={'TeamMember__title'}>
                {title}
            </div>
            {
                logo &&
                <div className={'TeamMember__logo-wrapper'}>
                    <img className={'TeamMember__logo'} src={logo}/>
                </div>
            }
        </a>
    )
}

const HomePage = () => {
    const {t} = useTranslation();

    useEffect(() => {
        return () => {
            document.body.style.backgroundColor = null;
        };
    }, []);

    return (
        <div className={'Page HomePage'}>
            <Section className={'TopSection'}
                     id={'about'}
            >
                <img src={'/images/backgrounds/back_hero@2x-80.jpg'}
                     className={'SectionBackground'}/>
                <div className={'Section__content'}>
                    <h1>
                        ZERO DEGREE
                        <br/>
                        IS A NEXT GENERATION INDUSTRY-TAILORED
                        <br/>
                        AI INFRASTRUCTURE
                    </h1>
                    <p>
                        Zero Degree is a next generation industry tailored AI engine and infrastructure built on domain
                        specific knowledge for healthcare, finance, transportation and entertainment industries,
                        addressing part of the $310 Billion AI market by 2026.
                        <br/>
                        <br/>
                        The platform brings together state-of-the-art proprietary machine learning, natural language
                        understanding models and computer vision algorithms providing an easy-to-use Programmatic API
                        interface to its AI engine.
                    </p>
                </div>
            </Section>

            <Section className={'TeamSection'}
                     id={'team'}
            >
                <div className={'Section__content'}>

                    <div className={'TeamSection__title'}>
                        ZERO DEGREE AI TEAM
                    </div>
                    <div className={'TeamMembers'}>
                        <TeamMember name={'MITCH LIU'}
                                    title={'Executive Operations'}
                                    avatar={'ml.png'}
                        />
                        <TeamMember name={'ALI FARAHANCHI'}
                                    title={'Business Operations'}
                                    avatar={'af.png'}
                                    externalLink={'https://alifarahanchi1.github.io/'}

                        />
                        <TeamMember name={'Coming Soon'}
                                    title={null}
                                    avatar={'placeholder.png'}
                        />
                        <TeamMember name={'Coming Soon'}
                                    title={null}
                                    avatar={'placeholder.png'}
                        />
                        <TeamMember name={'Coming Soon'}
                                    title={null}
                                    avatar={'placeholder.png'}
                        />
                    </div>
                    <div className={'TeamSection__quote'}>
                        Zero Degree, incorporated in 2023 in Singapore, is the first-ever industry specific AI engine
                        infrastructure built on domain specific knowledge. Incubated by DHVC based in Palo Alto, CA and
                        Shanghai with over $500M in Assets under management.
                    </div>
                    <div className={'TeamSection__group-title'}>
                        Advisory Board
                    </div>
                    <div className={'TeamMembers'}>
                        <AdvisorMember name={'Jaegul Choo'}
                                    title={'Associate Professor,\nGraduate School of\nArtificial Intelligence, KAIST'}
                                    externalLink={'https://www.linkedin.com/in/jaegulchoo/'}
                                    avatar={'advisors/jc.png'}
                        />
                        <AdvisorMember name={'Coming Soon'}
                                    title={null}
                                    avatar={'placeholder.png'}
                        />
                        <AdvisorMember name={'Coming Soon'}
                                    title={null}
                                    avatar={'placeholder.png'}
                        />
                    </div>
                </div>
            </Section>

            <Section className={'InfrastructureSection'}
                     id={'platform-overview'}
            >
                <img src={'/images/backgrounds/back_advanced_ai@2x-80.jpg'}
                     className={'SectionBackground'}/>
                <div className={'Section__content'}>

                    <div className={'InfrastructureSection__title'}>
                        ZERO DEGREE ADVANCED AI INFRASTRUCTURE
                    </div>
                    <div className={'InfrastructureSection__texts'}>
                        <p>
                            Pioneering industry tailored AI engine built on domain specific knowledge powered by a
                            distributed network of computation nodes.
                        </p>

                        <p>
                            Providing services to different industry entities including drug manufacturers, hospitals,
                            financial institutions, banks, logistics companies, and media & entertainment.
                        </p>
                    </div>
                    <img src={'/images/illos/graphic_1@2x.png'}/>
                </div>
            </Section>

            <Section className={'APISection'}>
                <div className={'Section__content'}>

                    <div className={'APISection__title'}>
                        SIMPLE PROGRAMMATIC API INTERFACE TO ZERO DEGREE AI ENGINE ENABLES GLOBAL ACCESS
                    </div>
                    <img src={'/images/illos/graphic_2@2x.png'}/>
                </div>
            </Section>

            <Section className={'IndustriesSection'}
                     id={'industries'}
            >
                <img src={'/images/backgrounds/back_specialized@2x-80.jpg'}
                     className={'SectionBackground'}/>

                <div className={'Section__content'}>

                    <div className={'IndustriesSection__title'}>
                        SPECIALIZED AI ENGINE TAILORED TO HEALTHCARE INDUSTRY (ex.)
                    </div>
                    <img src={'/images/illos/graphic_3@2x.png'}/>

                    <div className={'IndustriesSection__description'}>
                        Provide enterprise customers in the healthcare, finance, transportation and entertainment industries with both low-level and high-level AI data-model services.
                    </div>
                </div>
            </Section>
        </div>
    )
};

export default HomePage;